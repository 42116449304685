// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#banner-container {
    background-color: #282828;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

#banner-container .row {
    flex-wrap: wrap;
    /* justify-content: space-around; */
}

.banner {
    width: 100%;
    margin: 1rem 0;
}

.banner-img {
    width: 100%;
    border-radius: .5rem;
}

@media screen and (min-width: 768px) {
    #banner-container .row {
        justify-content: space-around;
        margin: 3rem 0;
        flex-wrap: nowrap;
    }

    .banner {
        width: 30%;
        margin: inherit;
        display: inherit;
    }

    .banner-img {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./components/CompanyBannerContainer/CompanyBannerContainer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,mCAAmC;AACvC;;AAEA;IACI,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI;QACI,6BAA6B;QAC7B,cAAc;QACd,iBAAiB;IACrB;;IAEA;QACI,UAAU;QACV,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":["#banner-container {\n    background-color: #282828;\n    padding-top: 2rem;\n    padding-bottom: 2rem;\n}\n\n#banner-container .row {\n    flex-wrap: wrap;\n    /* justify-content: space-around; */\n}\n\n.banner {\n    width: 100%;\n    margin: 1rem 0;\n}\n\n.banner-img {\n    width: 100%;\n    border-radius: .5rem;\n}\n\n@media screen and (min-width: 768px) {\n    #banner-container .row {\n        justify-content: space-around;\n        margin: 3rem 0;\n        flex-wrap: nowrap;\n    }\n\n    .banner {\n        width: 30%;\n        margin: inherit;\n        display: inherit;\n    }\n\n    .banner-img {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
