export { default as HomePage } from './HomePage';
import type * as logos from '../../img/exact-logos';

interface specialHeader {
    regularText: string,
    highlightText: string
}

interface companyDescriptionSectionText {
    sectionHeader: string,
    sectionDescription: string
}

interface companyDescriptionSectionSchema {
    themeColor: 'blue' | 'orange' | 'green',
    companyDescriptionImage: string,
    sectionTextContent: companyDescriptionSectionText[]
}

interface companyDescriptionSchema {
    exactControlsSection: companyDescriptionSectionSchema,
    exactSystemsSection: companyDescriptionSectionSchema,
    exactServicesSection: companyDescriptionSectionSchema,
}

interface contentType {
    pageType: 'servicesPage' | 'standard',
    welcomeDescription: string[],
    welcomeHeader: specialHeader,
    welcomeImage: keyof typeof logos,
    themeColor: 'blue' | 'orange' | 'green',
    companyDescription: companyDescriptionSchema
}

const content:contentType = {
    pageType: 'standard',
    welcomeDescription: [
        'We specialize in providing professional services in controls, automation, mechanical design, and drafting services; as well as comprehensive technology solutions such as process control network and integration solutions.'
    ],
    welcomeHeader: {
        regularText: "Welcome to ",
        highlightText: "Exact Controls"
    },
    welcomeImage: 'Exact_Controls_Emblem_Black',
    themeColor: 'blue',
    companyDescription: {
        exactControlsSection: {
            themeColor: 'blue',
            companyDescriptionImage: 'Exact_Controls_logo_color',
            sectionTextContent: [
                {
                    sectionHeader: 'Controls and Automation Solutions',
                    sectionDescription: 'We specialize in designing, programming, and implementing advanced automation systems, including PLCs, HMIs, and SCADA systems. Our automation solutions optimize industrial processes, improve operational efficiency, and reduce downtime, making your business more productive and competitive.'
                }
            ]
        },
        exactSystemsSection: {
            themeColor: 'orange',
            companyDescriptionImage: 'Exact_Systems_logo_color',
            sectionTextContent: [
                {
                    sectionHeader: 'Network Integration and Monitoring',
                    sectionDescription: "Our specialists provide seamless network integration and continuous monitoring to ensure your systems run smoothly and efficiently. We safeguard your infrastructure, troubleshoot issues, and enhance your network's performance with state-of-the-art solutions."
                }
            ]
        },
        exactServicesSection: {
            themeColor: 'green',
            companyDescriptionImage: 'Exact_Services_logo_color',
            sectionTextContent: [
                {
                    sectionHeader: 'Sales Engineering',
                    sectionDescription: "Our Sales Engineers bridge the gap between technical expertise and customer solutions. By understanding your unique challenges, they offer tailored automation and control solutions to meet your specific needs. With in-depth knowledge of the products and industry, they help guide you through the decision-making process to ensure the right technical solutions for your business."
                },
                {
                    sectionHeader: 'Drafting and 3D Modeling',
                    sectionDescription: "We bring your concepts to life with precision and accuracy through detailed technical drawings and 3D models. Whether for engineering, manufacturing, or construction projects, our CAD-based designs ensure clarity and compliance with industry standards."
                },
                {
                    sectionHeader: 'Front-End Development',
                    sectionDescription: "Our front-end development services deliver visually appealing, responsive, and user-friendly web and mobile interfaces that provide an optimal user experience. We ensure that your digital presence aligns with your brand identity while engaging your customers effectively."
                },
                {
                    sectionHeader: 'Project Management/Coordination',
                    sectionDescription: "We offer end-to-end project management solutions, overseeing every phase of your project from planning to delivery. By coordinating resources, managing timelines, and ensuring effective communication, we guarantee that your projects are completed on time and meet your business goals."
                }
            ]
        }
    }
}

export {specialHeader, companyDescriptionSectionText, companyDescriptionSchema, companyDescriptionSectionSchema}
export {content as homePageContent}