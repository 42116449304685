import React from "react";
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter, createBrowserRouter, Route, Router, RouterProvider, Routes } from 'react-router-dom';

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//     children: [
//       {
//         path: "/about-us",
//         element: <AboutUsPage />
//       },
//       {
//         path: "/contact-us",
//         element: <ContactPage />
//       },
//       {
//         path: "/services",
//         element: <ServicesPage />
//       },
//       {
//         path: "/exact-controls",
//         element: <ExactControlsPage />
//       },
//       {
//         path: "/exact-systems",
//         element: <ExactSystemsPage />
//       },
//       {
//         path: "/exact-services",
//         element: <ExactServicesPage />
//       }
//     ]
//   },
// ])

const rootElement = document.getElementById("root") as HTMLElement;

ReactDOM.createRoot(rootElement).render(
    <BrowserRouter>
        <React.StrictMode>
            {/* <RouterProvider router={router} /> */}
          <App/>
        </React.StrictMode>
    </BrowserRouter>
)
// ReactDOM.render(
//     document.getElementById('root')
// );
