import React, { useState } from 'react';
import { CompanyBannerContainer, CompanyDescriptionContainer, CompanyInfoContainer, WelcomeSection } from '..';
import { homePageContent } from './index';

const HomePage: React.FC = () => {
  // const [learnMoreLinks, setLearnMoreLinks] = useState(true)
  // const [pageContent, setPageContent] = useState(homePageContent)
  // const [pageType, setPageType] = useState(homePageContent.pageType)

  return (
    <div className='container'>
        <WelcomeSection pageType={homePageContent.pageType} learnMoreLinks={true} themeColor={homePageContent.themeColor} welcomeImage={homePageContent.welcomeImage} welcomeDescription={homePageContent.welcomeDescription} welcomeHeader={homePageContent.welcomeHeader}/>
        <CompanyBannerContainer />
        <CompanyDescriptionContainer learnMoreLinks={true} companyDescription={homePageContent.companyDescription}/>
        <CompanyInfoContainer />
    </div>
  )
};

export default HomePage;