import React from 'react';
import './NavigationBar.css'
import HamburgerIcon from '../../img/Hamburger_icon.svg';
import ExactControlsLogo from '../../img/Exact_Controls_logo_color.svg';
import { Link } from "react-router-dom";

interface NavigationBarProps {
    isMenuOpen: boolean;
    setIsMenuOpen: (isOpen: boolean) => void;
}

export default function NavigationBar({ isMenuOpen, setIsMenuOpen }: NavigationBarProps) {
  return (
    <header>
        <div className="nav-bar-container">
            <div className="logo-container">
                <Link to="/">
                    <img id="nav-logo" src={ExactControlsLogo} alt="Exact Controls" />
                </Link>                
            </div>
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="hamburgerIconButton">
                <img src={HamburgerIcon} alt="Hamburger Icon" />
            </button>
            <div className="nav-bar-links">
                <Link to="/services" className="nav-bar-link">Services</Link>
                <Link to="/about" className="nav-bar-link">About Us</Link>
                <Link to="/contact" className="nav-bar-link">Contact</Link>
            </div>
        </div>
    </header>
  )
};
