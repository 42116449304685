import React from 'react';
import './CompanyInfoContainer.css'
import { Link } from 'react-router-dom';
// import Hamburger_icon from '@img/Hamburger_icon'

const CompanyInfoContainer: React.FC = () => {
  return (
    <div id="company-information">
        <div className="company-info-container">
            <div className="company-info-section">
                <div className="info-title">
                    <h1>Contact</h1>
                </div>
                <div className="info-body contact-info">
                    <h3>info@exact.engineering</h3>
                </div>
            </div>
            <div className="company-info-section">
                <div className="info-title">
                    <h1>Locations</h1>
                </div>
                <div className="info-body location-info">
                    <div className="location-block">
                        <h3>Nevada</h3>
                    </div>
                    <div className="location-block">
                        <h3>Ohio</h3>
                    </div>
                </div>
            </div>
            <div className="company-info-section">
                <div className="info-title">
                    <h1>Links</h1>
                </div>
                <div className="info-body">
                    <Link to="/about" className="info-body-links">About Us</Link>
                    <Link to="/services" className="info-body-links">Our Services</Link>
                    <Link to="/contact" className="info-body-links">Contact Us</Link>
                </div>
            </div>
        </div>
    </div>
  )
};

export default CompanyInfoContainer;