// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    background-color: #282828;
    width: 100%;
    padding: 2rem 2rem;
    display: flex;
    justify-content: center;
    height: 6rem;
}

.nav-bar-container {
    display: flex;
    justify-content: space-between;
    /* padding: 0 2rem; */
    align-items: center;
    width: 100%;
    height: 100%;
}

.nav-bar-links {
    font-weight: 600;
    display: none;
}

.nav-bar-link {
    margin: 0 .5rem;
    padding: 1rem;
    text-decoration: none;
    color: #f0f0f0;
    border-radius: .25rem;

}

.nav-bar-link:hover {
    background-color: #4545C4;
}

.logo-container {
    /* width: 10rem; */
    height: 100%;
    /* min-width: 10rem; */
    /* margin-right: 1rem; */
}

#nav-logo {
    height: 100%;
}

.hamburgerIconButton {
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.hamburgerIconButton img {
    width: 100%;
    height: 100%;
}

#NavbarLogoPlaceHolderMobile {
    width: 100%;
    height: 100%;
    background-color: #4545C4;
}

@media screen and (min-width: 768px) {
    .hamburgerIconButton {
        display: none;
    }

    .nav-bar-links {
        display: flex;
    }
}`, "",{"version":3,"sources":["webpack://./components/NavigationBar/NavigationBar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;IACrB,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,qBAAqB;IACrB,cAAc;IACd,qBAAqB;;AAEzB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,wBAAwB;AAC5B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":["header {\n    background-color: #282828;\n    width: 100%;\n    padding: 2rem 2rem;\n    display: flex;\n    justify-content: center;\n    height: 6rem;\n}\n\n.nav-bar-container {\n    display: flex;\n    justify-content: space-between;\n    /* padding: 0 2rem; */\n    align-items: center;\n    width: 100%;\n    height: 100%;\n}\n\n.nav-bar-links {\n    font-weight: 600;\n    display: none;\n}\n\n.nav-bar-link {\n    margin: 0 .5rem;\n    padding: 1rem;\n    text-decoration: none;\n    color: #f0f0f0;\n    border-radius: .25rem;\n\n}\n\n.nav-bar-link:hover {\n    background-color: #4545C4;\n}\n\n.logo-container {\n    /* width: 10rem; */\n    height: 100%;\n    /* min-width: 10rem; */\n    /* margin-right: 1rem; */\n}\n\n#nav-logo {\n    height: 100%;\n}\n\n.hamburgerIconButton {\n    height: 100%;\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n}\n\n.hamburgerIconButton img {\n    width: 100%;\n    height: 100%;\n}\n\n#NavbarLogoPlaceHolderMobile {\n    width: 100%;\n    height: 100%;\n    background-color: #4545C4;\n}\n\n@media screen and (min-width: 768px) {\n    .hamburgerIconButton {\n        display: none;\n    }\n\n    .nav-bar-links {\n        display: flex;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
