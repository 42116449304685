import React from 'react';
import './CompanyBannerContainer.css'
import { Link } from 'react-router-dom';
import ExactControlsLogo from '../../img/Exact_Controls_Banner_Color.svg'
import ExactSystemsLogo from '../../img/Exact_Systems_Banner_Color.svg'
import ExactServicesLogo from '../../img/Exact_Services_Banner_color.svg'

const CompanyBannerContainer: React.FC = () => {
  return (
    <div id="banner-container">
        <div className="row">
            <Link to="/exact-controls" className="banner card">
                <img src={ExactControlsLogo} className='banner-img' alt='Exact Controls Logo'/>
            </Link>
            <Link to="/exact-systems" className="banner card">
                <img src={ExactSystemsLogo} className='banner-img' alt='Exact Systems Logo'/>
            </Link>
            <Link to="/exact-services" className="banner card">
                <img src={ExactServicesLogo} className='banner-img' alt='Exact Services Logo'/>
            </Link>
        </div>
    </div>
  )
};

export default CompanyBannerContainer;