import React, { useState } from 'react';
import { CompanyDescriptionContainer, CompanyInfoContainer, WelcomeSection } from '..';
import { exactControlsPageContent } from '.';

const ExactControlsPage: React.FC = () => {
  // const [learnMoreLinks, setLearnMoreLinks] = useState(false)
  // const [pageContent, setPageContent] = useState(exactControlsPageContent)
  // const [pageType, setPageType] = useState(exactControlsPageContent.pageType)

  return (
    <div className='container'>
        <WelcomeSection pageType={exactControlsPageContent.pageType} learnMoreLinks={false} themeColor={exactControlsPageContent.themeColor} welcomeImage={exactControlsPageContent.welcomeImage} welcomeDescription={exactControlsPageContent.welcomeDescription} welcomeHeader={exactControlsPageContent.welcomeHeader}/>
        <CompanyDescriptionContainer learnMoreLinks={false} companyDescription={exactControlsPageContent.companyDescription}/>
        <CompanyInfoContainer />
    </div>
  )
};

export default ExactControlsPage;