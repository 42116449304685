export { default as ExactControlsPage } from './ExactControlsPage';
import type * as logos from '../../img/exact-logos';

interface specialHeader {
    regularText: string,
    highlightText: string
}

interface companyDescriptionSectionText {
    sectionHeader: string,
    sectionDescription: string
}

interface companyDescriptionSectionSchema {
    themeColor: 'blue' | 'orange' | 'green',
    companyDescriptionImage: string,
    sectionTextContent: companyDescriptionSectionText[]
}

interface companyDescriptionSchema {
    exactControlsSection: companyDescriptionSectionSchema,
    exactSystemsSection: companyDescriptionSectionSchema,
    exactServicesSection: companyDescriptionSectionSchema,
}

interface contentType {
    pageType: 'servicesPage' | 'standard',
    welcomeDescription: string[],
    welcomeHeader: specialHeader,
    welcomeImage: keyof typeof logos,
    themeColor: 'blue' | 'orange' | 'green',
    companyDescription: companyDescriptionSchema
}

const content:contentType = {
    pageType: 'standard',
    welcomeDescription: [
        'We specialize in providing professional services in controls, automation, mechanical design, and drafting services; as well as comprehensive technology solutions such as process control network and integration solutions.'
    ],
    welcomeHeader: {
        regularText: "",
        highlightText: "Exact Controls"
    },
    welcomeImage: 'Exact_Controls_Emblem_Black',
    themeColor: 'blue',
    companyDescription: {
        exactControlsSection: {
            themeColor: 'blue',
            companyDescriptionImage: 'Exact_Controls_logo_color',
            sectionTextContent: [
                {
                    sectionHeader: 'Controls and Automation Solutions',
                    sectionDescription: 'We specialize in designing, programming, and implementing advanced automation systems, including PLCs, HMIs, and SCADA systems. Our automation solutions optimize industrial processes, improve operational efficiency, and reduce downtime, making your business more productive and competitive.'
                }
            ]
        },
        exactSystemsSection: {
            themeColor: 'orange',
            companyDescriptionImage: 'Exact_Systems_logo_color',
            sectionTextContent: []
        },
        exactServicesSection: {
            themeColor: 'green',
            companyDescriptionImage: 'Exact_Services_logo_color',
            sectionTextContent: []
        }
    }
}

export {specialHeader, companyDescriptionSectionText, companyDescriptionSchema, companyDescriptionSectionSchema}
export {content as exactControlsPageContent}