import React, { useState } from 'react';
import { CompanyDescriptionContainer, CompanyInfoContainer, WelcomeSection } from '..';
import { servicesPageContent } from '.';

const ServicesPage: React.FC = () => {
  // const [learnMoreLinks, setLearnMoreLinks] = useState(true)
  // const [ourServicesLink, setOurServicesLink] = useState(false)
  // const [pageType, setPageType] = useState(servicesPageContent.pageType)
  // const [pageContent, setPageContent] = useState(servicesPageContent)

  return (
    <div className='container'>
        <WelcomeSection pageType={servicesPageContent.pageType} learnMoreLinks={false} themeColor={servicesPageContent.themeColor} welcomeImage={servicesPageContent.welcomeImage} welcomeDescription={servicesPageContent.welcomeDescription} welcomeHeader={servicesPageContent.welcomeHeader}/>
        <CompanyDescriptionContainer learnMoreLinks={true} companyDescription={servicesPageContent.companyDescription}/>
        <CompanyInfoContainer />
    </div>
  )
};

export default ServicesPage;