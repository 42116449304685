import { CompanyInfoContainer, WelcomeSection } from '..';
import React, { useState } from 'react';
import { contactContent } from '.';

const ContactPage: React.FC = () => {
  // const [learnMoreLinks, setLearnMoreLinks] = useState(false)
  // const [pageContent, setPageContent] = useState(contactContent)
  // const [pageType, setPageType] = useState(contactContent.pageType)
  
  return (
    <div className='container'>
        <WelcomeSection pageType={contactContent.pageType} learnMoreLinks={false} themeColor={contactContent.themeColor} welcomeImage={contactContent.welcomeImage} welcomeDescription={contactContent.welcomeDescription} welcomeHeader={contactContent.welcomeHeader}/>
        <CompanyInfoContainer />
    </div>
  )
};

export default ContactPage;