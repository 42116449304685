import { CompanyInfoContainer, WelcomeSection } from '..';
import React, { useState } from 'react';
import { aboutUsContent } from '.';
const AboutUsPage: React.FC = () => {
  // const [learnMoreLinks, setLearnMoreLinks] = useState(false)
  // const [pageType, setPageType] = useState(aboutUsContent.pageType)
  // const [pageContent, setPageContent] = useState(aboutUsContent)

  return (
    <div className='container'>
        <WelcomeSection pageType={aboutUsContent.pageType} learnMoreLinks={false} themeColor={aboutUsContent.themeColor} welcomeImage={aboutUsContent.welcomeImage} welcomeDescription={aboutUsContent.welcomeDescription} welcomeHeader={aboutUsContent.welcomeHeader}/>
        <CompanyInfoContainer />
    </div>
  )
};

export default AboutUsPage;