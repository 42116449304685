export { default as ExactSystemsPage } from './ExactSystemsPage';
import type * as logos from '../../img/exact-logos';

interface specialHeader {
    regularText: string,
    highlightText: string
}

interface companyDescriptionSectionText {
    sectionHeader: string,
    sectionDescription: string
}

interface companyDescriptionSectionSchema {
    themeColor: 'blue' | 'orange' | 'green',
    companyDescriptionImage: string,
    sectionTextContent: companyDescriptionSectionText[]
}

interface companyDescriptionSchema {
    exactControlsSection: companyDescriptionSectionSchema,
    exactSystemsSection: companyDescriptionSectionSchema,
    exactServicesSection: companyDescriptionSectionSchema,
}

interface contentType {
    pageType: 'servicesPage' | 'standard',
    welcomeDescription: string[],
    welcomeHeader: specialHeader,
    welcomeImage: keyof typeof logos,
    themeColor: 'blue' | 'orange' | 'green',
    companyDescription: companyDescriptionSchema
}

const content:contentType = {
    pageType: 'standard',
    welcomeDescription: [
        'We specialize in providing professional services in controls, automation, mechanical design, and drafting services; as well as comprehensive technology solutions such as process control network and integration solutions.'
    ],
    welcomeHeader: {
        regularText: "",
        highlightText: "Exact Systems"
    },
    welcomeImage: 'Exact_Systems_Emblem_Black',
    themeColor: 'orange',
    companyDescription: {
        exactControlsSection: {
            themeColor: 'blue',
            companyDescriptionImage: 'Exact_Controls_logo_color',
            sectionTextContent: []
        },
        exactSystemsSection: {
            themeColor: 'orange',
            companyDescriptionImage: 'Exact_Systems_logo_color',
            sectionTextContent: [
                {
                    sectionHeader: 'Network Integration and Monitoring',
                    sectionDescription: "Our specialists provide seamless network integration and continuous monitoring to ensure your systems run smoothly and efficiently. We safeguard your infrastructure, troubleshoot issues, and enhance your network's performance with state-of-the-art solutions."
                }
            ]
        },
        exactServicesSection: {
            themeColor: 'green',
            companyDescriptionImage: 'Exact_Services_logo_color',
            sectionTextContent: []
        }
    }
}

export {specialHeader, companyDescriptionSectionText, companyDescriptionSchema, companyDescriptionSectionSchema}
export {content as exactSystemsPageContent}