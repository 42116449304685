import React, { useState } from 'react';
import { CompanyDescriptionContainer, CompanyInfoContainer, WelcomeSection } from '..';
import { exactServicesPageContent } from '.';

const ExactServicesPage: React.FC = () => {
  // const [learnMoreLinks, setLearnMoreLinks] = useState(false)
  // const [pageContent, setPageContent] = useState(exactServicesPageContent)
  // const [pageType, setPageType] = useState(exactServicesPageContent.pageType)

  return (
    <div className='container'>
        <WelcomeSection pageType={exactServicesPageContent.pageType} learnMoreLinks={false} themeColor={exactServicesPageContent.themeColor} welcomeImage={exactServicesPageContent.welcomeImage} welcomeDescription={exactServicesPageContent.welcomeDescription} welcomeHeader={exactServicesPageContent.welcomeHeader}/>
        <CompanyDescriptionContainer learnMoreLinks={false} companyDescription={exactServicesPageContent.companyDescription}/>
        <CompanyInfoContainer />
    </div>
  )
};

export default ExactServicesPage; 