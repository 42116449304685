import React from 'react';
import './CompanyDescriptionContainer.css'
import { companyDescriptionSchema, companyDescriptionSectionSchema, companyDescriptionSectionText } from '../HomePage/index';
import ExactControlsLogo from '../../img/Exact_Controls_logo_color.svg'
import ExactSystemsLogo from '../../img/Exact_Systems_logo_color.svg'
import ExactServicesLogo from '../../img/Exact_Services_logo_color.svg'
import { Link } from 'react-router-dom';

const CompanyDescriptionContainer: React.FC<{ learnMoreLinks:boolean, companyDescription: companyDescriptionSchema }> = ({ learnMoreLinks, companyDescription }) => {
  return (
    <div id="company-description">
        {companyDescription.exactControlsSection.sectionTextContent.length > 0 && (
            <div className="row description-section">
                <div className="description-section-logo background-blue">
                    <Link to="/exact-controls" className="description-section-logo-link">
                        <img src={ExactControlsLogo} alt="Exact Controls Logo"/>
                    </Link>
                </div>
                <div className="description-section-info">
                    {companyDescription.exactControlsSection.sectionTextContent.map(( content:companyDescriptionSectionText ) => {
                        return(
                            <React.Fragment key={content.sectionHeader}>
                                <h1 className="font-blue">{content.sectionHeader}</h1>
                                <h3>{content.sectionDescription}</h3>
                            </React.Fragment>
                        )
                    })}
                    {learnMoreLinks && (
                        <Link to="/exact-controls" className="style-dark-button blue-button">Learn More</Link>
                    )}
                </div>
            </div>
        )}
        {companyDescription.exactSystemsSection.sectionTextContent.length > 0 && (
            <div className="row description-section">
                <div className="description-section-logo background-orange">
                    <Link to="/exact-systems" className="description-section-logo-link">
                        <img src={ExactSystemsLogo} alt="Exact Systems Logo"/>
                    </Link>
                </div>
                <div className="description-section-info">
                {companyDescription.exactSystemsSection.sectionTextContent.map(( content:companyDescriptionSectionText ) => {
                        return(
                            <React.Fragment key={content.sectionHeader}>
                                <h1 className="font-orange">{content.sectionHeader}</h1>
                                <h3>{content.sectionDescription}</h3>
                            </React.Fragment>
                        )
                    })}
                    {learnMoreLinks && (
                        <Link to="/exact-systems" className="style-dark-button orange-button">Learn More</Link>
                    )}
                </div>
            </div>
        )}
        {companyDescription.exactServicesSection.sectionTextContent.length > 0 && (
            <div className="row description-section">
                <div className="description-section-logo background-green">
                    <Link to="/exact-services" className="description-section-logo-link">
                        <img src={ExactServicesLogo} alt="Exact Services Logo"/>
                    </Link>
                </div>
                <div className="description-section-info">
                {companyDescription.exactServicesSection.sectionTextContent.map(( content:companyDescriptionSectionText ) => {
                        return(
                            <React.Fragment key={content.sectionHeader}>
                                <h1 className="font-green">{content.sectionHeader}</h1>
                                <h3>{content.sectionDescription}</h3>
                            </React.Fragment>
                        )
                    })}
                    {learnMoreLinks && (
                        <Link to="/exact-services" className="style-dark-button green-button">Learn More</Link>
                    )}
                </div>
            </div>
        )}
    </div>
  )
};

export default CompanyDescriptionContainer;