import React, { useState } from 'react';
import { CompanyDescriptionContainer, CompanyInfoContainer, WelcomeSection } from '..';
import { exactSystemsPageContent } from '.';

const ExactSystemsPage: React.FC = () => {
  // const [learnMoreLinks, setLearnMoreLinks] = useState(false)
  // const [pageContent, setPageContent] = useState(exactSystemsPageContent)
  // const [pageType, setPageType] = useState(exactSystemsPageContent.pageType)

  return (
    <div className='container'>
        <WelcomeSection pageType={exactSystemsPageContent.pageType} learnMoreLinks={false} themeColor={exactSystemsPageContent.themeColor} welcomeImage={exactSystemsPageContent.welcomeImage} welcomeDescription={exactSystemsPageContent.welcomeDescription} welcomeHeader={exactSystemsPageContent.welcomeHeader}/>
        <CompanyDescriptionContainer learnMoreLinks={false} companyDescription={exactSystemsPageContent.companyDescription}/>
        <CompanyInfoContainer />
    </div>
  )
};

export default ExactSystemsPage;