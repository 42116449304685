import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import XIcon from '../../img/X_icon.svg';
import './NavigationMenu.css';

interface NavigationMenuProps {
    onClose: () => void;
}

export default function NavigationMenu({ onClose }: NavigationMenuProps) {
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(onClose, 200); // Match this with your animation duration
    };

    return (
        <div id="modal-container">
            <div id="nav-menu-container" className={isClosing ? 'closing' : ''}>
                <div className="close-menu-container">
                    <button onClick={handleClose} className="close-menu-button">
                        <img src={XIcon} alt="Close Menu" />
                    </button>
                </div>
                <div className="link-container">
                    <Link to="/about" className="nav-menu-link" onClick={onClose}>About Us</Link>
                    <Link to="/services" className="nav-menu-link" onClick={onClose}>Services</Link>
                    <Link to="/contact" className="nav-menu-link" onClick={onClose}>Contact</Link>
                </div>
            </div>
        </div>
    );
}