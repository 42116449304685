import React from 'react';
import './WelcomeSection.css'
import { specialHeader } from '../HomePage/index';
import ExactControlsLogo from '../../img/Exact_Controls_Banner_Color.svg'
import ExactSystemsLogo from '../../img/Exact_Systems_Banner_Color.svg'
import ExactServicesLogo from '../../img/Exact_Services_Banner_color.svg'
import { Link } from 'react-router-dom';
import * as logos from '../../img/exact-logos';

interface WelcomeSectionProps {
    pageType: 'servicesPage' | 'standard';
    learnMoreLinks: boolean;
    themeColor: 'blue' | 'orange' | 'green';
    welcomeDescription: string[];
    welcomeHeader: specialHeader;
    welcomeImage: keyof typeof logos;
  }

const WelcomeSection: React.FC<WelcomeSectionProps> = ({ pageType, learnMoreLinks, themeColor, welcomeDescription, welcomeHeader, welcomeImage }) => {
    // const navigate = useNavigate();

    if (pageType === 'servicesPage') {
        return (
            <div id="welcome-section-services">
                <div className="welcome-body-services">
                    <h1>
                        {welcomeHeader.regularText}<span className={`font-${themeColor}`}>{welcomeHeader.highlightText}</span>
                    </h1>
                    { welcomeDescription.map((text: string, index: number) => { return ( 
                        <h3 key={index}>{text}</h3> 
                    )})}
                </div>
                <div id="logo-emblem-services">
                    <Link to="/exact-controls" className='logo-emblem-link'>
                        <img src={ExactControlsLogo} alt="Exact Controls"/>
                    </Link>
                    <Link to="/exact-systems" className='logo-emblem-link'>
                        <img src={ExactSystemsLogo} alt="Exact Systems"/>
                    </Link>
                    <Link to="/exact-services" className='logo-emblem-link'>
                        <img src={ExactServicesLogo} alt="Exact Services"/>
                    </Link>
                </div>
            </div>
        )
    } else {
        return (
            <div id="welcome-section"> 
                <div id="logo-emblem">
                    <img src={logos[welcomeImage]} alt="Company Logo" />
                </div>
                <div className="welcome-body">
                    <h1>
                        {welcomeHeader.regularText}<span className={`font-${themeColor}`}>{welcomeHeader.highlightText}</span>
                    </h1>
                    { 
                    // currently using index until we get the data from a database with unique ids
                    welcomeDescription.map((text: string, index: number) => { return ( 
                        <h3 key={index}>{text}</h3> 
                    )})}
                    {learnMoreLinks && (
                        <div className='welcome-section-button-container'>
                            <Link to="/services" className="style-dark-button blue-button">
                                Our Services
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        )
    }
};

export default WelcomeSection;