export { default as ExactServicesPage } from './ExactServicesPage';
import type * as logos from '../../img/exact-logos';

interface specialHeader {
    regularText: string,
    highlightText: string
}

interface companyDescriptionSectionText {
    sectionHeader: string,
    sectionDescription: string
}

interface companyDescriptionSectionSchema {
    themeColor: 'blue' | 'orange' | 'green',
    companyDescriptionImage: string,
    sectionTextContent: companyDescriptionSectionText[]
}

interface companyDescriptionSchema {
    exactControlsSection: companyDescriptionSectionSchema,
    exactSystemsSection: companyDescriptionSectionSchema,
    exactServicesSection: companyDescriptionSectionSchema,
}

interface contentType {
    pageType: 'servicesPage' | 'standard',
    welcomeDescription: string[],
    welcomeHeader: specialHeader,
    welcomeImage: keyof typeof logos,
    themeColor: 'blue' | 'orange' | 'green',
    companyDescription: companyDescriptionSchema
}

const content:contentType = {
    pageType: 'standard',
    welcomeDescription: [
        'We specialize in providing professional services in controls, automation, mechanical design, and drafting services; as well as comprehensive technology solutions such as process control network and integration solutions.'
    ],
    welcomeHeader: {
        regularText: "",
        highlightText: "Exact Services"
    },
    welcomeImage: 'Exact_Services_Emblem_Black',
    themeColor: 'green',
    companyDescription: {
        exactControlsSection: {
            themeColor: 'blue',
            companyDescriptionImage: 'Exact_Controls_logo_color',
            sectionTextContent: []
        },
        exactSystemsSection: {
            themeColor: 'orange',
            companyDescriptionImage: 'Exact_Systems_logo_color',
            sectionTextContent: []
        },
        exactServicesSection: {
            themeColor: 'green',
            companyDescriptionImage: 'Exact_Services_logo_color',
            sectionTextContent: [
                {
                    sectionHeader: 'Sales Engineering',
                    sectionDescription: "Our Sales Engineers bridge the gap between technical expertise and customer solutions. By understanding your unique challenges, they offer tailored automation and control solutions to meet your specific needs. With in-depth knowledge of the products and industry, they help guide you through the decision-making process to ensure the right technical solutions for your business."
                },
                {
                    sectionHeader: 'Drafting and 3D Modeling',
                    sectionDescription: "We bring your concepts to life with precision and accuracy through detailed technical drawings and 3D models. Whether for engineering, manufacturing, or construction projects, our CAD-based designs ensure clarity and compliance with industry standards."
                },
                {
                    sectionHeader: 'Front-End Development',
                    sectionDescription: "Our front-end development services deliver visually appealing, responsive, and user-friendly web and mobile interfaces that provide an optimal user experience. We ensure that your digital presence aligns with your brand identity while engaging your customers effectively."
                },
                {
                    sectionHeader: 'Project Management/Coordination',
                    sectionDescription: "We offer end-to-end project management solutions, overseeing every phase of your project from planning to delivery. By coordinating resources, managing timelines, and ensuring effective communication, we guarantee that your projects are completed on time and meet your business goals."
                }
            ]
        }
    }
}

export {specialHeader, companyDescriptionSectionText, companyDescriptionSchema, companyDescriptionSectionSchema}
export {content as exactServicesPageContent}