export { default as ContactPage } from './ContactPage';
import type * as logos from '../../img/exact-logos';

interface specialHeader {
    regularText: string,
    highlightText: string
}

interface companyDescriptionSectionText {
    sectionHeader: string,
    sectionDescription: string
}

interface companyDescriptionSectionSchema {
    themeColor: 'blue' | 'orange' | 'green',
    companyDescriptionImage: string,
    sectionTextContent: companyDescriptionSectionText[]
}

interface companyDescriptionSchema {
    exactControlsSection: companyDescriptionSectionSchema,
    exactSystemsSection: companyDescriptionSectionSchema,
    exactServicesSection: companyDescriptionSectionSchema,
}


interface contentType {
    pageType: 'servicesPage' | 'standard',
    welcomeDescription: string[],
    welcomeHeader: specialHeader,
    welcomeImage: keyof typeof logos,
    themeColor: 'blue' | 'orange' | 'green',
    companyDescription: companyDescriptionSchema
}

const content:contentType = {
    pageType: 'standard',
    welcomeDescription: [
        'info@exact.engineering'
    ],
    welcomeHeader: {
        regularText: '',
        highlightText:"Contact Us"
    },
    welcomeImage: 'Exact_Controls_Emblem_Black',
    themeColor: 'blue',
    companyDescription: {
        exactControlsSection: {
            themeColor: 'blue',
            companyDescriptionImage: 'Exact_Controls_logo_color',
            sectionTextContent: []
        },
        exactSystemsSection: {
            themeColor: 'orange',
            companyDescriptionImage: 'Exact_Systems_logo_color',
            sectionTextContent: []
        },
        exactServicesSection: {
            themeColor: 'green',
            companyDescriptionImage: 'Exact_Services_logo_color',
            sectionTextContent: []
        }
    }
}

export {content as contactContent}